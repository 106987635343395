import { Layout } from 'antd'
import styled from '@emotion/styled'

import { LogoNameSvg, LogoSvg } from '@assets/svg'
import { breakpoints } from '@style'

const flexTop = 0.26
const flexBot = 0.1

export const Wrap = styled(Layout.Content)`
  &&& {
    flex: unset;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.background};
    overflow: scroll;

    @media only screen and (max-width: ${breakpoints.sm.max.string}) {
      width: 100%;
    }
  }
`

export const Top = styled.div`
  flex: ${flexTop};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 0;
`

export const Mid = styled.div`
  flex: ${1 - flexTop - flexBot};
`

export const Bot = styled.div`
  flex: ${flexBot};
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.title};
`

export const TextIntroduce = styled.div`
  font-size: 1.125rem;
  max-width: 385px;
`

export const TextOrganization = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.textBlur};
`

export const WrapLogo = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 96px;
  margin-bottom: 15px;
`

export const Logo = styled(LogoSvg)`
  height: 32px;
  width: 70px;
`

export const LogoName = styled(LogoNameSvg)`
  height: 24px;
  width: 96px;
`
