import { MailOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

export const Wrap = styled.div`
  position: absolute;
  width: 100%;
  height: 46px;
  left: 0px;
  top: 0px;
  background: #f7e8e8;
  display: flex;
  align-items: center;
  padding-left: 16px;
  z-index: 1;
`

export const Image = styled(MailOutlined)`
  width: 20px;
  height: 20px;
`

export const Content = styled.div`
  margin-left: 10px;
  color: #1c2a4b;
  font-size: 0.875rem;
`

export const Span = styled.span`
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  padding-bottom: 2px;
`

export const SpanCol = styled.span`
  font-weight: 600;
  margin-left: 5px;
`
