import { css } from '@emotion/react'

import { colors } from './colors'

export const customStyle = css`
  /* stylelint-disable */
  /* stylelint-enable */
  .ant-btn-primary {
    background-color: ${colors.primary};
  }
  .ant-btn-dashed {
    color: ${colors.primary};
    border-color: ${colors.primary};
  }
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background-color: #44677b;
        color: #f9f9fb;
        border: solid 0.5px #d1d1d6;
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        border: solid 0.5px #d1d1d6;
      }
    }
  }
  .ant-collapse {
    .ant-collapse-header {
      font-weight: 600;
      font-size: 20px;
      flex-direction: row-reverse;
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        background-color: #df5b28;
        color: #fff;
        border-radius: 8px !important;
      }
    }
    .ant-collapse-content-active {
      border-top: none;
    }
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      font-size: 16px;
      &:hover {
        color: #df5b28 !important;
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #df5b28 !important;
      }
    }
    .ant-tabs-ink-bar {
      background-color: #df5b28 !important;
    }
  }
`
