import styled from '@emotion/styled'
import { DatePicker, DatePickerProps, Form } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import { Controller } from 'react-hook-form'

const dateFormat = 'YYYY/MM/DD'

export const LabelDatePicker = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`

export const WrapDatePicker = styled.div`
  .ant-picker {
    width: 100%;
  }
`

type Props = {
  label?: string
  error?: string
  control: any
  name: string
}

export const DatePickerCustom: FC<DatePickerProps & Props> = ({ label, error, name, control }) => {
  return (
    <WrapDatePicker>
      <Form.Item name={name} hasFeedback help={error} validateStatus={error ? 'error' : ''}>
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <div>
              <LabelDatePicker>{label}</LabelDatePicker>
              <DatePicker
                format={dateFormat}
                value={value ? dayjs(value, dateFormat) : null}
                onChange={e => onChange(e)}
              />
            </div>
          )}
        />
      </Form.Item>
    </WrapDatePicker>
  )
}
