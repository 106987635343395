import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'

const WrapInput = styled.textarea<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px !important` : '100%')};
`
export const WrapSpan = styled.div<{ height?: number }>`
  min-width: 100px;
  min-height: 25px;
`

interface EditableFieldProps {
  initialValue: string
  onChange?: (value: string) => void
}

export const EditableField: React.FC<EditableFieldProps> = ({ initialValue, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [spanHeight, setSpanHeight] = useState(0)
  const spanRef = useRef(null)

  useEffect(() => {
    setValue(initialValue)
    setSpanHeight(0)
  }, [initialValue])

  const handleDoubleClick = () => {
    if (!isEditing) {
      const spanElement: any = spanRef.current
      if (spanElement) {
        const height = spanElement?.getBoundingClientRect?.()?.height
        setSpanHeight(height)
      }
    }
    setIsEditing(true)
  }

  const handleInputChange = (e: any) => {
    setValue(e.target.value)
  }

  const handleInputBlur = () => {
    setIsEditing(false)
  }

  const handleInputKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e?.shiftKey) {
      // Save the edited value and exit editing mode
      setIsEditing(false)
      onChange?.(value)
    }
  }

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <WrapInput
          // type='text'
          value={value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleInputKeyPress}
          autoFocus
          height={spanHeight}
        />
      ) : (
        <WrapSpan ref={spanRef}>{value}</WrapSpan>
      )}
    </div>
  )
}

export default EditableField
