// > Workspace
export const workspace = {
  WORKSPACE: '/',
  WORKSPACE_OVERVIEW: '/:idProject/overview',
  WORKSPACE_MEASUREMENT: '/:idProject/measurement',
  MODEL: ':idProject/model'
}
export const project = {
  PROJECT: '/',
  PROJECT_LIST: '/list',
  PROJECT_DELIVERY: '/delivery',
  PROJECT_DETAIL_DELIVERY: '/delivery/:idProject',
  PROJECT_FEATURE: '/feature',
  PROJECT_DETAIL_FEATURE: '/feature/:featureId',
  PROJECT_OVERVIEW: '/:idProject/overview',
  PROJECT_MEASUREMENT: '/:idProject/measurement',
  MODEL: ':idProject/model'
}

const workspaceRouters: Record<keyof typeof workspace, string> = {} as any
Object.keys(workspace).forEach(key => ((workspaceRouters as any)[key] = `/workspace${(workspace as any)[key]}`))

const projectRouters: Record<keyof typeof project, string> = {} as any
Object.keys(project).forEach(key => ((projectRouters as any)[key] = `/project${(project as any)[key]}`))

const mainRouters = {
  LOGIN: '/signin',
  REGISTER: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY: '/verify',
  RESET_PASSWORD: '/resetpwd',
  UNAUTHORIZED: '/401',
  NOT_FOUND: '/404',
  SERVER_ERROR: '/500',
  COMMING_SOON: '/coming-soon',
  UNDER_MAINTENANCE: '/under-maintenance',
  PRIVACY_POLICY: '/privacy-and-policy',
  THEME: '/theme'
}

export const ROUTERS = {
  ...mainRouters,
  ...workspaceRouters,
  ...projectRouters
}

export const ROUTERS_GUARD = [ROUTERS.WORKSPACE]
