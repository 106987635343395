import styled from '@emotion/styled'
import { Layout, Menu } from 'antd'

const { Header, Content } = Layout

export const Container = styled(Layout)`
  height: 100vh;
`

export const CustomMenu = styled(Menu)`
  padding: 0;
  background: #ffffff;
  border-bottom: none;
  .ant-menu-item-selected {
    color: #df5b28 !important;
  }
  li {
    &:first-child {
      padding-left: 0px;
    }
    &::after {
      display: none;
    }
  }
`

export const ItemNavigate = styled.span`
  color: #df5b28 !important;
  font-size: 20px;
  font-weight: 600;
`

export const CustomHeader = styled(Header)`
  padding-top: 0px;
  padding-left: 120px;
  padding-right: 120px;
  min-height: 68px;
  background: #ffffff;
  .ant-menu-overflow-item {
    display: flex;
    align-items: center;
  }
`

export const CustomContent = styled(Content)`
  height: auto;
`
