import { DEFAULT_QUERIES } from '@defines'
import { PaginationQueries, PaginationSortItem } from '@types'

import { capitalize } from './string'

export const queriesToString = (options?: { defaultQueries?: boolean; currentQueries?: PaginationQueries }) => {
  const search = new URLSearchParams()
  const queries = options?.defaultQueries || !options?.currentQueries ? DEFAULT_QUERIES : options.currentQueries
  Object.keys(queries).forEach(o => {
    const value = (queries as any)[o]
    if (o === 'sort') {
      if (typeof value === 'object' && value.length) {
        const sortItems = value as PaginationSortItem[]
        const sortString = [...sortItems].map(oo => `${oo.field},${oo.sort}`).join('|')
        search.append('sort', sortString)
      }
    } else if (o === 'keyword') {
      if (value) search.append('keyword', value.trim().toLowerCase())
    } else if (o === 'page') {
      search.append('page', value + 1)
    } else search.append(o, value)
  })
  return search
}

export const optionWithExtraValue = (options: { label: string; value: string }[], value: string) => {
  if (!value) {
    return options
  }
  if (options?.some(o => o.value === value)) {
    return options
  }
  return (options || [])?.concat({
    label: capitalize(value),
    value: value
  })
}
