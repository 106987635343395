import { createSelector } from '@reduxjs/toolkit'

import { ENVIRONMENT } from '@defines'

const selectSelf = (state: RootState) => state.app

export const selector = {
  isDevelopMode: createSelector(selectSelf, state =>
    process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION ? false : state.developMode
  )
}
