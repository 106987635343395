import { Layout, Typography } from 'antd'
import styled from '@emotion/styled'

import { FrameCoverSvg } from '@assets/svg'
import { breakpoints } from '@style'

export const Wrap = styled(Layout.Sider)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.colorLightPrimary} !important;

  & .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${breakpoints.sm.max.string}) {
    display: none;
  }
`

export const Image = styled(FrameCoverSvg)`
  width: 100%;
  max-width: 734px;
  margin-bottom: 94px;
`

export const Title = styled(Typography.Title)`
  &&& {
    font-size: 1.5rem;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.title};
  }
`

export const Text = styled.span`
  font-size: 1.125rem;
  max-width: 400px;
  text-align: center;
`
