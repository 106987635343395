import { css } from '@emotion/react'

export const fontStyle = css`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/Roboto-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/static/fonts/Roboto-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: url('/static/fonts/Roboto-Bold.otf') format('opentype');
  }
`
