import { useEffect, useRef, useState } from 'react'
import { Button, Skeleton, notification } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ProjectApi } from '@api'
import { projectActions, projectSelector } from '@store'

import { Wrap, WrapCard, WrapGenerate } from './styles'

export const Recruitement: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const refQueue = useRef<NodeJS.Timeout>()
  const dispatch = useDispatch()
  const jobsDescription = useSelector(projectSelector.selectJD)
  const [activeJd, setActiveJd] = useState<string>('')

  const { idProject } = useParams()

  const handleDownloadFile = async (id: string, name: string) => {
    try {
      setDownloading(true)
      const res = (await ProjectApi.downloadJd(id)) as any
      const href = URL.createObjectURL(res)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `${String(name)?.replaceAll(' ', '_')}.pdf` || 'file.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    } finally {
      setDownloading(false)
    }
  }
  const renderLoading = () => {
    if (jobsDescription && !loading) {
      return jobsDescription?.map(i => {
        if (downloading && activeJd === i._id) {
          return (
            <WrapCard key={i._id}>
              <Skeleton
                active
                round
                title={{
                  width: '40%'
                }}
                paragraph={{
                  rows: 3,
                  className: 'content-skeleton',
                  width: '100%'
                }}
              />
            </WrapCard>
          )
        }
        return (
          <WrapCard
            key={i._id}
            onClick={() => {
              handleDownloadFile(i._id, i.title)
              setActiveJd(i._id)
            }}
            active={activeJd === i._id}
          >
            <div className='title'>{i.title}</div>
            <div className='content'>{i.responsibility}</div>
          </WrapCard>
        )
      })
    }
    return new Array(4).fill(0)?.map((i, index) => (
      <WrapCard key={i + String(index)}>
        <Skeleton
          active
          round
          title={{
            width: '40%'
          }}
          paragraph={{
            rows: 3,
            className: 'content-skeleton',
            width: '100%'
          }}
        />
      </WrapCard>
    ))
  }

  const clearRefQueue = () => {
    if (refQueue.current) {
      clearTimeout(refQueue.current)
      refQueue.current = undefined
    }
  }

  const tryFetch = async (handle: () => Promise<void>, options?: { time?: number }) => {
    let success = false
    try {
      await handle()
      success = true
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    } finally {
      setLoading(false)
      if (!success) {
        clearRefQueue()
        refQueue.current = setTimeout(() => {
          clearRefQueue()
          tryFetch(handle, options)
        }, options?.time || 1000)
      }
    }
  }

  const handleGetJobDescription = () => {
    tryFetch(
      async () => {
        setLoading(true)
        const res = (await ProjectApi.getJobDescriptionByProjectRequest(idProject)) as any
        if (res) {
          dispatch(projectActions.setJobsDescription(res))
        } else throw new Error()
      },
      { time: 10000 }
    )
  }

  useEffect(() => {
    if (idProject) {
      handleGetJobDescription()
    }
  }, [idProject])

  const handleGenerate = () => {
    handleGetJobDescription()
  }

  return (
    <Wrap>
      <WrapGenerate>
        <Button type='primary' loading={!jobsDescription || loading} onClick={handleGenerate}>
          {!jobsDescription || loading ? 'Generating' : 'Generate'}
        </Button>
      </WrapGenerate>
      {renderLoading()}
    </Wrap>
  )
}
