import { Layout } from 'antd'
import { ReactNode, Suspense } from 'react'
import { useNavigate } from 'react-router-dom'

import { GlobalLoading } from '@components'
import { ROUTERS } from '@defines'
import { ArrowLeftIconSvg } from '@assets/svg'

import { Container, CustomContent, CustomHeader, CustomMenu, ItemNavigate } from './styles'

interface Props {
  children: ReactNode
}

export const ProjectLayout = ({ children }: Props) => {
  const navigate = useNavigate()

  const renderMenuItems = () => {
    const goBack = {
      key: '',
      label: '',
      icon: <ArrowLeftIconSvg />,
      onClick: () => navigate(-1)
    }
    if (location.pathname?.includes(ROUTERS.PROJECT_FEATURE)) {
      return [
        goBack,
        {
          key: '',
          label: <ItemNavigate>Feature</ItemNavigate>
        }
      ]
    }
    if (location.pathname?.includes(ROUTERS.PROJECT_DELIVERY)) {
      return [
        {
          key: '',
          label: '',
          icon: <ArrowLeftIconSvg />,
          onClick: () => navigate(ROUTERS.PROJECT_LIST)
        },
        {
          key: '',
          label: <ItemNavigate>Delivery</ItemNavigate>
        }
      ]
    }
    return [
      {
        key: ROUTERS.PROJECT,
        label: 'Start Project'
      },
      {
        key: ROUTERS.PROJECT_LIST,
        label: 'Project List'
      }
    ]
  }
  return (
    <Container>
      <Layout>
        <CustomHeader>
          <CustomMenu
            mode='horizontal'
            selectedKeys={[location.pathname]}
            onClick={e => {
              if (!e.key) {
                return
              }
              navigate(e.key)
            }}
            items={renderMenuItems()}
          />
        </CustomHeader>
        <CustomContent>
          <Suspense fallback={<GlobalLoading />}>{children}</Suspense>
        </CustomContent>
      </Layout>
    </Container>
  )
}
