import styled from '@emotion/styled'
import { Collapse } from 'antd'

export const Wrap = styled.div`
  background-color: #f0f0f0;
  overflow-x: auto;
  padding: 36px 120px;
  min-height: calc(100vh - 68px);
`

export const Header = styled.div`
  background-color: #fff;
  padding: 24px;
  border-bottom: solid 1px #f0f0f0;
  border-radius: 8px 8px 0px 0px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`

export const Content = styled.div`
  background-color: #fff;
  padding: 32px 36px;
  border-radius: 0px 0px 8px 8px;
`

export const CustomCollapse = styled(Collapse)`
  margin-bottom: 24px;
`
export const SubTitle = styled.div`
  margin: 8px 0px;
`

export const WrapList = styled.div`
  padding: 12px;
  border-radius: 6px;
  border: solid 1px #f0f0f0;
  ul {
    padding-left: 12px;
  }
`
export const WrapTable = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`

export const WrapAdjust = styled.div`
  text-align: center;
`
