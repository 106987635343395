import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EpicModel, FeatureModel, JobDescription, ProjectModel, StoryModel } from '@types'

import { State } from './type'

const initialState: State = {}

export const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clean: () => initialState,
    setProject: (state, action: PayloadAction<ProjectModel | undefined>) => {
      state.data = action.payload
    },
    setListProject: (state, action: PayloadAction<ProjectModel[]>) => {
      state.list = action.payload
    },
    setEpics: (state, action: PayloadAction<EpicModel[] | undefined>) => {
      state.epics = action.payload
    },
    setFeatures: (state, action: PayloadAction<FeatureModel[] | undefined>) => {
      state.features = action.payload
    },
    setStories: (state, action: PayloadAction<StoryModel[] | undefined>) => {
      state.stories = action.payload
    },
    setJobsDescription: (state, action: PayloadAction<JobDescription[] | undefined>) => {
      state.jobsDescription = action.payload
    }
  }
})

// > Export
// * Action
export const actions = {
  ...slice.actions
}
// * Reducer
export const { reducer } = slice
