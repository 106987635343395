import { AliasToken, SeedToken } from 'antd/es/theme/internal'

const colorsDefines = {
  primary: '#df5b28',
  black: '#000000',
  error100: '#FEE4E2',
  error800: '#912018',
  neutral200: '#EAECF0',
  neutral300: '#D0D5DD',
  neutral400: '#98A2B3',
  neutral50: '#F9FAFB',
  neutral500: '#667085',
  neutral600: '#475467',
  neutral700: '#344054',
  neutral800: '#1D2939',
  neutral900: '#101828',
  primary200: '#93ADFD',
  primary500: '#3350F2',
  semGreen500: '#12B76A',
  semYellow500: '#F79009',
  success100: '#D1FADF',
  success400: '#32D583',
  success500: '#12B76A',
  success800: '#05603A',
  warning100: '#FEF0C7',
  warning300: '#FEC84B',
  warning50: '#FFFAEB',
  warning600: '#DC6803',
  warning800: '#93370D',
  white: '#FFFFFF'
}

export const colors: typeof colorsDefines & Partial<SeedToken> & Partial<AliasToken> & Record<string, string> = {
  ...colorsDefines,
  colorPrimary: '#4CAF50',
  colorLightPrimary: '#C8E6C9',
  colorDarkPrimary: '#388E3C'
}
