import styled from '@emotion/styled'

export const Wrap = styled.div`
  background-color: #fff;
  overflow-x: auto;
  margin-top: 36px;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #df5b28;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #df5b28;
  }
`
export const WrapRow = styled.div`
  display: flex;
  margin-bottom: 16px;
`
export const Sprint = styled.div<{ active?: boolean }>`
  font-size: 12px;
  color: ${({ active }) => (active ? '#5400D3' : '#df5b28')};
`

export const CustomBlockHead = styled.div`
  padding: 0px 16px 28px 0px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  border-bottom: solid 1px #f0f0f0;
`

export const CustomBlock = styled.div<{ color?: string }>`
  background-color: ${({ color }) => (color ? color : '#fff')};
  height: 116px;
  min-width: 250px;
  padding: 16px;
  overflow: auto;
  text-align: center;
  border: solid 1px #fff;
  cursor: pointer;
  word-break: break-all;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #df5b28;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #df5b28;
  }
`

export const CustomEpickBlock = styled.div`
  background-color: #f0f0f0;
  height: 116px;
  width: 250px;
  min-width: 250px;
  padding: 16px;
  overflow: auto;
  word-break: break-all;
  .name {
    color: #df5b28;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #df5b28;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #df5b28;
  }
`
export const WrapGenerate = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

export const WrapCard = styled.div<{ active?: boolean }>`
  border-radius: 16px;
  border: ${({ active }) => (active ? '1px solid #df5b28' : '1px solid #c7c7cc')};
  padding: 24px 36px;
  background: #fff;
  margin-bottom: 24px;
  .title {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .content {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .content-skeleton {
    li {
      height: 10px !important;
    }
  }
`

export const WrapField = styled.div``
