import { Table, notification, Modal, Skeleton } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { OverflowEmpty } from '@components'
import { ProjectApi } from '@api'
import { EditIconSvg, RemoveIconSvg } from '@assets/svg'
import { ROUTERS } from '@defines'
import { projectActions, projectSelector } from '@store'
import { capitalize, formatDateUS } from '@utils'
import { ProjectModel } from '@types'

import { CustomTag, Dot, Wrap, WrapIcons } from './styles'

const STATUS_COLORS: any = {
  creating: '#FAAD14',
  created: '#32D74B',
  ready: '#32D74B',
  planning: '#FF4D4F',
  onGoing: '#FAAD14'
}

interface DataType {
  no: number
  id: string
  name: string
  status: string
  owner: string
  startDate: string
  delivery: string
}

export const ProjectList: React.FC = () => {
  const navigate = useNavigate()
  const list = useSelector(projectSelector.selectList)
  const dispatch = useDispatch()
  const [modal, contextHolder] = Modal.useModal()
  const [loading, setLoading] = useState(true)

  const handleGetList = async () => {
    try {
      setLoading(true)
      const res = await ProjectApi.getProjectsRequest()
      dispatch(projectActions.setListProject(res as unknown as ProjectModel[]))
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id: string) => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you want to proceed this?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await ProjectApi.deleteProjectsRequest(id)
          notification.info({
            message: 'Delete project successfully'
          })
          handleGetList()
        } catch (error) {
          notification.warning({
            message: String(error)
          })
        }
      }
    })
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no'
    },
    {
      title: 'Project List',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <CustomTag>
          <Dot color={STATUS_COLORS[String(text).toLocaleLowerCase()] || '#32D74B'} />
          {text}
        </CustomTag>
      )
    },
    {
      title: 'Owner',
      key: 'owner',
      dataIndex: 'owner'
    },
    // {
    //   title: 'Start Date',
    //   key: 'startDate',
    //   dataIndex: 'startDate'
    // },
    {
      title: 'Delivery',
      dataIndex: 'delivery',
      key: 'delivery',
      render: (_, item) => (
        <WrapIcons>
          <EditIconSvg onClick={() => navigate(`${ROUTERS.PROJECT_DELIVERY}/${item.id}`)} />
          <RemoveIconSvg onClick={() => handleDelete(item.id)} />
        </WrapIcons>
      )
    }
  ]

  const data: DataType[] = useMemo(
    () =>
      list?.map((item, index) => ({
        no: index + 1,
        id: item._id,
        name: item.name,
        status: capitalize(item.status),
        owner: item.owner_name,
        startDate: formatDateUS(item.start_date),
        delivery: ''
      })) || [],
    [list]
  )

  useEffect(() => {
    handleGetList()
  }, [])
  const renderTable = useMemo(() => {
    if (loading) {
      return (
        <Skeleton
          active
          round
          title={{
            width: '100%'
          }}
          paragraph={{
            rows: 12,
            width: '100%'
          }}
        />
      )
    }
    if (!data?.length) {
      return <OverflowEmpty />
    }
    return <Table columns={columns} dataSource={data} />
  }, [loading, data, columns])
  return (
    <Wrap>
      {contextHolder}
      {renderTable}
    </Wrap>
  )
}
