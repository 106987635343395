import { FC } from 'react'
import { Form, Input, InputProps } from 'antd'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'

const { TextArea } = Input

export const LabelTextArea = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`

export const WrapTextArea = styled.div`
  .ant-input-affix-wrapper {
    margin-bottom: 0px !important;
  }
  .ant-input-data-count {
    bottom: 8px;
    right: 10px;
  }
`

type Props = {
  label?: string
  error?: string
  control?: any
  name: string
  valueProps?: string
  onChangeValues?: (e: any) => void
  autoSize?: {
    minRows?: number
    maxRows?: number
  }
}

export const TextAreaCustom: FC<InputProps & Props> = ({
  label,
  error,
  control,
  placeholder,
  maxLength,
  name,
  valueProps,
  onChangeValues,
  autoSize
}) => {
  const renderInput = ({ value, onChange }: any) => {
    return (
      <div>
        <LabelTextArea>{label}</LabelTextArea>
        <TextArea
          showCount
          maxLength={maxLength}
          style={{ height: 120, marginBottom: 24 }}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          autoSize={
            autoSize || {
              minRows: 5,
              maxRows: 10
            }
          }
        />
      </div>
    )
  }
  if (!control) {
    return renderInput({ value: valueProps, onChange: onChangeValues })
  }
  return (
    <WrapTextArea>
      <Form.Item name={name} hasFeedback help={error} validateStatus={error ? 'error' : ''}>
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => renderInput({ value, onChange })}
        />
      </Form.Item>
    </WrapTextArea>
  )
}
