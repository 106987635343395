import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'

import { ROUTERS } from '@defines'
import { projectSelector } from '@store'
import { EpicModel } from '@types'
import { EditableField, OverflowEmpty } from '@components'
import { ProjectApi } from '@api'

import { CustomBlock, CustomBlockHead, CustomEpickBlock, Sprint, Wrap, WrapRow, WrapField } from './styles'

const colors = ['#73AFEA', 'rgba(253, 188, 96, 0.80)', 'rgba(178, 157, 241, 0.80)', 'rgba(117, 208, 155, 0.80)']

export const CustomCalendar: React.FC = () => {
  const epics = useSelector(projectSelector.selectEpics)
  const features = useSelector(projectSelector.selectFeatures)
  const data = useSelector(projectSelector.selectData)
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const ListHeader = [
    { name: data?.name || '', sprint: null }
    // { name: 'January', sprint: 'Sprint xx - xx' },
    // { name: 'February', sprint: 'Sprint xx - xx' },
    // { name: 'March', sprint: 'Sprint xx - xx' },
    // { name: 'April', sprint: 'Sprint xx - xx' },
    // { name: 'May', sprint: 'Sprint xx - xx' },
    // { name: 'June', sprint: 'Sprint xx - xx' },
    // { name: 'July', sprint: 'Sprint xx - xx' },
    // { name: 'August', sprint: 'Sprint xx - xx' },
    // { name: 'September', sprint: 'Sprint xx - xx' },
    // { name: 'October', sprint: 'Sprint xx - xx' },
    // { name: 'November', sprint: 'Sprint xx - xx' },
    // { name: 'December', sprint: 'Sprint xx - xx' }
  ]

  const ListData = useMemo(() => {
    if (!epics?.length) {
      return []
    }
    return epics?.map((epic: EpicModel, index: number) => ({
      ...epic,
      name: `Epic ${index + 1}`,
      features: features?.filter((i: any) => String(i.epic_id) === String(epic._id))
    }))
  }, [epics, features])

  // [
  //   {
  //     epic: 'Epic1',
  //     features: [
  //       { name: 'feature 1', month: 1, color: '#73AFEA' },
  //       { name: 'feature 2', month: 2, color: '#FDBC60' },
  //       { name: 'feature 1', month: 3, color: '#B29DF1' }
  //     ]
  //   }
  // ]

  const handleUpdateStory = async (item: any) => {
    try {
      setLoading(true)
      const params = {
        epicId: item?._id,
        title: item?.title,
        objective: item?.objective
      }
      await ProjectApi.updateEpicRequest(params)
      notification.success({
        message: 'Update successfully'
      })
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrap>
      <WrapRow>
        {ListHeader?.map(item => (
          <CustomBlockHead key={item.name}>
            <div>{item.name}</div>
            {item.sprint && <Sprint>{item.sprint}</Sprint>}
          </CustomBlockHead>
        ))}
      </WrapRow>
      {ListData?.length ? (
        ListData?.map((item: any) => (
          <WrapRow key={item.name}>
            <CustomEpickBlock>
              <div className='name'>{item.name}</div>
              <div className='content'>
                <WrapField>
                  <b> Goal: </b>
                  <EditableField
                    initialValue={item?.title}
                    onChange={e => {
                      handleUpdateStory({
                        ...item,
                        title: e
                      })
                    }}
                  />
                </WrapField>
                <WrapField>
                  <b>Objective:</b>{' '}
                  <EditableField
                    initialValue={item.objective}
                    onChange={e => {
                      handleUpdateStory({
                        ...item,
                        objective: e
                      })
                    }}
                  />
                </WrapField>
              </div>
            </CustomEpickBlock>

            {item?.features?.map((feature: any, i: number) => (
              <CustomBlock
                key={feature.name}
                color={colors?.[i % colors?.length]}
                onClick={() => navigate(`${ROUTERS.PROJECT_FEATURE}/${feature._id}`)}
              >
                {feature.title}
              </CustomBlock>
            ))}
          </WrapRow>
        ))
      ) : (
        <OverflowEmpty />
      )}
    </Wrap>
  )
}
