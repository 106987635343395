import { Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { GlobalLoading } from '@components'
import { ROUTERS } from '@defines'

const LoginPage = lazy(() => import('@pages/login'))
const RegisterPage = lazy(() => import('@pages/register'))
const ForgotPasswordPage = lazy(() => import('@pages/forgotPassword'))
const VerifyPage = lazy(() => import('@pages/verify'))
const ResetPasswordPage = lazy(() => import('@pages/resetPassword'))
const NotFoundPage = lazy(() => import('@pages/notfound'))
const ServerErrorPage = lazy(() => import('@pages/serverError'))
const UnderMaintenancePage = lazy(() => import('@pages/underMaintenance'))
const PrivacyPolicyPage = lazy(() => import('@pages/privacyPolicy'))
const ThemePage = lazy(() => import('@pages/theme'))

import { EmptyRouter } from './EmptyRouter'
import { ProjectRouter } from './ProjectRouter'
import { useRouteRender } from './hooks'

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoading />}>
        <Routes>
          <Route path={ROUTERS.LOGIN} element={useRouteRender(<LoginPage />)} />
          <Route path={ROUTERS.REGISTER} element={useRouteRender(<RegisterPage />)} />
          <Route
            path={ROUTERS.VERIFY}
            element={useRouteRender(<VerifyPage />, { checkVerify: false, autoRedirect: false })}
          />
          <Route path={ROUTERS.FORGOT_PASSWORD} element={useRouteRender(<ForgotPasswordPage />)} />
          <Route path={ROUTERS.RESET_PASSWORD} element={useRouteRender(<ResetPasswordPage />)} />
          <Route path={ROUTERS.NOT_FOUND} element={useRouteRender(<NotFoundPage />)} />
          <Route path={ROUTERS.SERVER_ERROR} element={useRouteRender(<ServerErrorPage />)} />
          <Route path={ROUTERS.UNDER_MAINTENANCE} element={useRouteRender(<UnderMaintenancePage />)} />
          <Route path={ROUTERS.PRIVACY_POLICY} element={useRouteRender(<PrivacyPolicyPage />)} />
          <Route path={ROUTERS.THEME} element={useRouteRender(<ThemePage />)} />
          <Route path='/project/*' element={useRouteRender(<ProjectRouter />)} />
          <Route path='/' element={useRouteRender(<EmptyRouter />)} />
          <Route path='*' element={<Navigate to={ROUTERS.NOT_FOUND} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
