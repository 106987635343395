import { FC } from 'react'
import { Form, Input, InputProps } from 'antd'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`

type Props = {
  label?: string
  error?: string
  control?: any
  name: string
  valueProps?: string
  onChangeValues?: (e: any) => void
}

export const CustomInput: FC<InputProps & Props> = ({
  prefix,
  label,
  error,
  control,
  type,
  placeholder,
  maxLength,
  name,
  onChangeValues,
  valueProps,
  ...props
}) => {
  const renderInput = ({ value, onChange, onBlur }: any) => {
    return (
      <div>
        <Label>{label}</Label>
        <Input
          prefix={prefix}
          type={type}
          placeholder={placeholder || ''}
          maxLength={maxLength || 100}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...props}
        />
      </div>
    )
  }
  if (!control) {
    return renderInput({ value: valueProps, onChange: onChangeValues })
  }
  return (
    <Form.Item name={name} hasFeedback help={error} validateStatus={error ? 'error' : ''}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange, onBlur } }) => renderInput({ value, onChange, onBlur })}
      />
    </Form.Item>
  )
}
