import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ROUTERS, storage } from '@defines'
import { userSelector } from '@store'

export type Options = { checkVerify?: boolean; autoRedirect?: boolean }

type Props = PropsWithChildren & {
  options?: Options
}

const Container: React.FC<Props> = ({ children, options }) => {
  const navigate = useNavigate()
  const userData = useSelector(userSelector.selectData)
  const [searchParams] = useSearchParams()
  const listSeach = searchParams.toString()

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const redirect = search.get('redirect')
    const token = localStorage.getItem(storage.TOKEN)

    if (token && location.pathname !== ROUTERS.LOGIN) {
      return
    }
    if (token && location.pathname === ROUTERS.LOGIN) {
      navigate(ROUTERS.PROJECT)
    }

    if (!token) {
      navigate(`${ROUTERS.LOGIN}?redirect=${redirect ? redirect : `${location.pathname}?${listSeach}`}`)
      return
    }
    if (options?.autoRedirect) {
      // handle auto later
      return
    }
    if (redirect && token) {
      navigate(redirect)
      return
    }
    // if (token) {
    //   navigate(ROUTERS.PROJECT)
    // }
  }, [userData])

  return <>{children}</>
}

export const useRouteRender = (node: ReactNode, options?: Options) => {
  return <Container options={options}>{node}</Container>
}
