import { Layout } from 'antd'
import { PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import { Content, ContentProps } from './components'

const Wrap = styled(Layout)`
  width: 100vw;
  height: 100vh;
`

export const AuthLayout: React.FC<PropsWithChildren<ContentProps>> = ({ children, ...props }) => {
  return (
    <Wrap>
      <Content {...props}>{children}</Content>
    </Wrap>
  )
}
