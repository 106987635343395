import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ENVIRONMENT, storage } from '@defines'

import { State } from './type'

const initialState: State = { developMode: false }

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clean: () => {},
    setDevelopMode: (state, action: PayloadAction<boolean>) => {
      state.developMode = action.payload
      localStorage.setItem(storage.ENVIRONMENT, action.payload ? ENVIRONMENT.DEVELOPMENT : ENVIRONMENT.PRODUCTION)
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
