import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Row } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { AddIconSvg } from '@assets/svg'
import { CustomInput, TextAreaCustom } from '@components'
import { useController } from '@hooks'
import { FormProjectModel, ProjectCreation } from '@types'

import { Title, WrapAddBtn, WrapTeam } from './styles'

const teamDefault = (id?: string | number) => ({
  id: id || 1,
  role: '',
  responsibility: ''
})

type Props = {
  handleSubmitApi?: (params: ProjectCreation) => void
  loading?: boolean
}

export const ProjectForm: React.FC<Props> = ({ handleSubmitApi, loading }) => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const { controller } = useController()

  const [teams, setTeams] = useState([teamDefault()])

  const schema = yup.object().shape({
    name: yup.string().trim().required('This field is required'),
    duration: yup.string().trim().required('This field is required'),
    // start_date: yup.string().trim().required('This field is required'),
    // end_date: yup.string().trim().required('This field is required'),
    values: yup.string().trim().required('This field is required'),
    short_desc: yup.string().trim().required('This field is required'),
    technical_stack: yup.string().trim().required('This field is required')
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const handleForm = async (formData: FormProjectModel) =>
    controller(
      async () => {
        handleSubmitApi?.({
          name: formData.name,
          duration: formData.duration,
          owner_name: 'Huyvk4',
          // start_date: formData.start_date,
          // end_date: formData.end_date,
          values: formData?.values || '',
          short_desc: formData.short_desc,
          technical_stack: formData.technical_stack,
          teams: teams?.map(i => ({ role: i.role, responsibility: i.responsibility })) || [],
          epics: []
          // status: 'string'
        })
      },
      {
        notice: {
          fallbackMessage: t('error.sign_up_error')
        }
      }
    )

  const onSubmit = handleSubmit(handleForm)

  const onChangeTeam = (id: string | number, name: string, value: string) => {
    const newChange = teams.map(i => {
      if (i.id === id) {
        return {
          ...i,
          [name]: value
        }
      }
      return i
    })
    setTeams(newChange)
  }

  const handleAddTeam = () => {
    const newData = [...teams]
    newData.push(teamDefault(newData?.length + 1))
    setTeams(newData)
  }

  return (
    <div>
      <Form
        name='normal_login'
        className='login-form'
        initialValues={{
          remember: true
        }}
        onFinish={onSubmit}
      >
        <Row justify='center' gutter={32}>
          <Col span={12}>
            <CustomInput
              name='name'
              control={control}
              label='Project name'
              placeholder='Enter project name'
              maxLength={100}
              error={errors.name?.message}
            />
          </Col>
          <Col span={12}>
            <CustomInput
              name='duration'
              control={control}
              label='Project duration'
              placeholder='Enter project duration'
              maxLength={100}
              error={errors.duration?.message}
            />
          </Col>
          {/* <Col span={6}>
            <DatePickerCustom
              name='start_date'
              control={control}
              label='Start date'
              format='DD.MM.YYYY'
              error={errors.start_date?.message}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              name='end_date'
              format='DD.MM.YYYY'
              control={control}
              label='End date'
              error={errors.end_date?.message}
            />
          </Col> */}

          <Col span={12}>
            <CustomInput
              name='values'
              control={control}
              label='Project values'
              placeholder='Project values'
              error={errors.values?.message}
            />
          </Col>
          <Col span={12} />
          <Col span={12}>
            <TextAreaCustom
              name='short_desc'
              control={control}
              label='Project short description'
              placeholder='Project short description'
              error={errors.short_desc?.message}
              maxLength={1000}
            />
          </Col>
          <Col span={12}>
            <TextAreaCustom
              name='technical_stack'
              control={control}
              label='Project technical stack'
              placeholder='Project technical stack'
              error={errors.technical_stack?.message}
              maxLength={1000}
            />
          </Col>
          <Col span={24}>
            <Title>Teams:</Title>
          </Col>
        </Row>

        {teams?.map(team => (
          <WrapTeam justify='center' gutter={32} key={team.id}>
            <Col span={12}>
              <CustomInput
                name='role'
                // control={control}
                label='Role'
                placeholder='Role'
                maxLength={100}
                value={team.role}
                onChangeValues={e => onChangeTeam(team.id, 'role', e?.target?.value)}
                // error={errors.projectName?.message}
              />
            </Col>
            <Col span={12}>
              <TextAreaCustom
                name='responsibility'
                label='Responsibility'
                placeholder='Responsibility'
                value={team?.responsibility}
                onChangeValues={e => onChangeTeam(team.id, 'responsibility', e?.target?.value)}
                maxLength={1000}
                autoSize={{
                  minRows: 1,
                  maxRows: 10
                }}
              />
            </Col>
          </WrapTeam>
        ))}
        <Row justify='center' gutter={32}>
          <WrapAddBtn span={24}>
            <Button type='dashed' onClick={handleAddTeam}>
              Add <AddIconSvg className='icon' />
            </Button>
          </WrapAddBtn>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={loading}>
                Start Planning
              </Button>{' '}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
