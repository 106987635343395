import { EpicUpdateModel, ProjectCreation, StoryModel } from 'src/types/project'

import { Api } from './config'

class Project extends Api {
  public postProjectRequest = (payload: ProjectCreation) => {
    return this.post('api/v1/projects/', payload)
  }

  public getProjectsRequest = () => {
    return this.get('api/v1/projects/')
  }

  public deleteProjectsRequest = (id?: string) => {
    return this.delete(`api/v1/projects/${id}`)
  }

  public getListEpicByProjectRequest = (projectId?: string) => {
    return this.get(`api/v1/epics/project/${projectId}`)
  }

  public getListAllFeatureRequest = () => {
    return this.get('api/v1/features/')
  }

  public getStoryByFeatureRequest = (featureId?: string) => {
    return this.get(`api/v1/stories/feature/${featureId}`)
  }

  public updateStoryByFeatureRequest = (params: StoryModel) => {
    return this.put(`api/v1/stories/${params._id}`, params)
  }

  public getProjectDetailRequest = (projectId?: string) => {
    return this.get(`api/v1/projects/${projectId}`)
  }

  public getJobDescriptionByProjectRequest = (projectId?: string) => {
    return this.get(`api/v1/job-descriptions/project/${projectId}`)
  }

  public downloadJd = (id?: string) => {
    return this.get(`api/v1/job-descriptions/download-pdf/${id}`, {
      responseType: 'blob' // important
    })
  }

  public updateEpicRequest = (params: EpicUpdateModel) => {
    const { epicId, ...param } = params
    return this.put(`api/v1/epics/${epicId}`, param)
  }
}

export const ProjectApi = new Project()
