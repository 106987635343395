import styled from '@emotion/styled'
import { Col, Row } from 'antd'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`

export const WrapAddBtn = styled(Col)`
  text-align: right;
  .icon {
    margin-left: 5px;
  }
  &:hover {
    .icon {
      path {
        fill: #6fbd6f;
      }
    }
  }
`
export const WrapTeam = styled(Row)`
  margin-bottom: 20px;
`
