import { notification } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ProjectApi } from '@api'
import { OverflowEmpty } from '@components'
import { projectActions, projectSelector } from '@store'
import { StoryModel } from '@types'

import { Story } from './components'
import { Content, Header, Wrap } from './styles'

export const Feature: React.FC = () => {
  const { featureId } = useParams()
  const dispatch = useDispatch()
  const stories = useSelector(projectSelector.selectStories)

  const getStories = async () => {
    try {
      const listStories = await ProjectApi.getStoryByFeatureRequest(featureId)
      dispatch(projectActions.setStories(listStories as any as StoryModel[]))
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    }
  }

  useEffect(() => {
    if (featureId) {
      getStories()
    }
  }, [featureId])

  return (
    <Wrap>
      <Header>Stories</Header>
      <Content>
        {stories?.length ? (
          stories?.map(story => {
            return <Story key={story._id} story={story} />
          })
        ) : (
          <OverflowEmpty />
        )}
      </Content>
    </Wrap>
  )
}
