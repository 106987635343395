import { css } from '@emotion/react'

import { fontStyle } from './fonts'
import { customStyle } from './custom'

export const globalStyle = css`
  ${fontStyle}
  ${customStyle}
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    font-family: Roboto, sans-serif;
    line-height: 1.5rem;
    margin: 0;
  }
`
