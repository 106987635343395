import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ROUTERS, storage } from '@defines'
import { userSelector } from '@store'
import { Loading } from '@components'

export const EmptyRouter: React.FC = () => {
  const navigate = useNavigate()
  const userData = useSelector(userSelector.selectData)

  useEffect(() => {
    const token = localStorage.getItem(storage.TOKEN)
    if (!token) {
      navigate(ROUTERS.LOGIN, { replace: true })
    } else {
      navigate(ROUTERS.PROJECT, { replace: true })
    }
  }, [userData])

  return <Loading />
}
