import styled from '@emotion/styled'
import { Tabs } from 'antd'

export const Wrap = styled.div`
  margin-top: 24px;
  background-color: #fff;
`

export const WrapTabs = styled(Tabs)`
  padding: 28px 120px 52px 120px;
`
