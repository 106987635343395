import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import { useState } from 'react'

import { ProjectCreation } from '@types'
import { ProjectApi } from '@api'
import { ROUTERS } from '@defines'

import { ProjectForm } from './components'
import { Wrap } from './styles'

export const ProjectPage: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const handleSubmitApi = async (params: ProjectCreation) => {
    try {
      setLoading(true)
      await ProjectApi.postProjectRequest(params)
      navigate(ROUTERS.PROJECT_LIST)
      notification.info({
        message: 'Create project successfully'
      })
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <Wrap>
      <ProjectForm handleSubmitApi={handleSubmitApi} loading={loading} />
    </Wrap>
  )
}
