import { Route, Routes } from 'react-router-dom'

import { ProjectLayout } from '@layouts'
import { project } from '@defines'
import { ProjectList, ProjectPage, Delivery, Feature } from '@pages/project'

export const ProjectRouter: React.FC = () => {
  return (
    <ProjectLayout>
      <Routes>
        <Route index element={<ProjectPage />} />
        <Route path={project.PROJECT_OVERVIEW} element={<ProjectPage />} />
        <Route path={project.PROJECT_LIST} element={<ProjectList />} />
        <Route path={project.PROJECT_DELIVERY} element={<Delivery />} />
        <Route path={project.PROJECT_DETAIL_DELIVERY} element={<Delivery />} />
        <Route path={project.PROJECT_DETAIL_FEATURE} element={<Feature />} />
        <Route path={project.PROJECT_FEATURE} element={<Feature />} />
      </Routes>
    </ProjectLayout>
  )
}
