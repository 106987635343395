import { PropsWithChildren } from 'react'

import { Mid, Wrap } from './styles'

export type ContentProps = {
  title: string
  isShowIntroduction?: boolean
}

export const Content: React.FC<PropsWithChildren<ContentProps>> = ({ children }) => {
  return (
    <Wrap>
      <Mid>{children}</Mid>
      {/* <Bot>
        <Flex center='vertical'>
          <TextOrganization>
            <CorporateSvg /> {t('message.copyright', { interpolation: { escapeValue: true } })}
          </TextOrganization>
        </Flex>
      </Bot> */}
    </Wrap>
  )
}
