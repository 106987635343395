import { Button, Select, Table, notification } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useEffect, useState } from 'react'

import { ProjectApi } from '@api'
import { StoryModel, TaskModel } from '@types'
import { capitalize, optionWithExtraValue } from '@utils'
import { EditableField } from '@components'

import { CustomCollapse, SubTitle, WrapAdjust, WrapList, WrapTable } from './styles'

const Prorities = ['High', 'Medium', 'Low']
const Roles = ['backend developer', 'frontend developer', 'devops']

export const Story: React.FC<{ story: StoryModel }> = ({ story }) => {
  const [currentStory, setCurrentStory] = useState<StoryModel>(story)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setCurrentStory(story)
  }, [story])

  const changeStory = (key: string, value: any) => {
    setCurrentStory({
      ...currentStory,
      [key]: value
    })
  }

  const columns: ColumnsType<TaskModel> = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, _) => (
        <EditableField
          initialValue={text}
          onChange={value => {
            const newTasks = currentStory?.tasks?.map((task, index) => {
              if (_.index === index + 1) {
                return {
                  ..._,
                  title: value
                }
              }
              return task
            })
            changeStory('tasks', newTasks)
          }}
        />
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, _) => (
        <EditableField
          initialValue={text}
          onChange={value => {
            const newTasks = currentStory?.tasks?.map((task, index) => {
              if (_.index === index + 1) {
                return {
                  ..._,
                  description: value
                }
              }
              return task
            })
            changeStory('tasks', newTasks)
          }}
        />
      )
    },
    {
      title: 'Assignee Role',
      key: 'assignee_role',
      dataIndex: 'assignee_role',
      render: (text, _) => (
        <div>
          <Select
            value={text}
            style={{ width: 148 }}
            onChange={value => {
              const newTasks = currentStory?.tasks?.map((task, index) => {
                if (_.index === index + 1) {
                  return {
                    ..._,
                    assignee_role: value
                  }
                }
                return task
              })

              changeStory('tasks', newTasks)
            }}
            options={optionWithExtraValue(
              Roles?.map(i => ({
                value: i,
                label: capitalize(i)
              })),
              text
            )}
          />
        </div>
      )
    },
    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
      render: (text, _) => (
        <div>
          <Select
            value={text}
            style={{ width: 148 }}
            onChange={value => {
              const newTasks = currentStory?.tasks?.map((task, index) => {
                if (_.index === index + 1) {
                  return {
                    ..._,
                    priority: value
                  }
                }
                return task
              })

              changeStory('tasks', newTasks)
            }}
            options={optionWithExtraValue(
              Prorities?.map(i => ({
                value: i,
                label: capitalize(i)
              })),
              text
            )}
          />
        </div>
      )
    }
  ]

  const handleData = (tableData: TaskModel[]): TaskModel[] => {
    return (
      tableData?.map((task: TaskModel, index: number) => ({
        ...task,
        index: index + 1
      })) || []
    )
  }

  const handleAdjust = async () => {
    try {
      setLoading(true)
      await ProjectApi.updateStoryByFeatureRequest(currentStory)
      notification.success({
        message: 'Update successfully'
      })
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <CustomCollapse
      items={[
        {
          key: '1',
          label: currentStory.title,
          children: (
            <div>
              <Select
                value={currentStory.priority}
                style={{ width: 148 }}
                onChange={e => changeStory('priority', e)}
                options={optionWithExtraValue(
                  Prorities?.map(i => ({
                    value: i,
                    label: capitalize(i)
                  })),
                  currentStory.priority
                )}
              />

              <SubTitle>Description:</SubTitle>
              <WrapList>
                <ul>
                  <li>
                    <EditableField
                      initialValue={currentStory.description}
                      onChange={e => changeStory('description', e)}
                    />
                  </li>
                </ul>
              </WrapList>

              {currentStory?.acceptance_criterias?.length && (
                <>
                  <SubTitle>Acceptance Criterias:</SubTitle>
                  <WrapList>
                    <ul>
                      {currentStory?.acceptance_criterias?.map((cri: string, index) => (
                        <li key={`acceptance_criterias-${String(cri)}`}>
                          <EditableField
                            initialValue={cri}
                            onChange={e => {
                              const criterias = currentStory?.acceptance_criterias?.map((i, sIndex) =>
                                sIndex === index ? e : i
                              )
                              changeStory('acceptance_criterias', criterias)
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </WrapList>
                </>
              )}
              <WrapTable>
                <Table columns={columns} dataSource={handleData(currentStory.tasks)} pagination={false} />
              </WrapTable>
              <WrapAdjust>
                <Button type='primary' onClick={handleAdjust} loading={loading}>
                  Adjust
                </Button>
              </WrapAdjust>
            </div>
          )
        }
      ]}
    />
  )
}
