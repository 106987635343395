import { notification } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import type { TabsProps } from 'antd'

import { ProjectApi } from '@api'
import { projectActions } from '@store'
import { EpicModel, FeatureModel, ProjectModel } from '@types'

import { CustomCalendar, Recruitement } from './components'
import { Wrap, WrapTabs } from './styles'

const TABS = {
  PLAN: 'PLAN',
  RECRUITE: 'RECRUITE'
}

export const Delivery: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TABS.PLAN)
  const dispatch = useDispatch()
  const { idProject } = useParams()
  const navigate = useNavigate()

  const getEpic = async () => {
    try {
      const res = await ProjectApi.getListEpicByProjectRequest(idProject)
      dispatch(projectActions.setEpics(res as any as EpicModel[]))
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    }
  }

  const getFeatures = async () => {
    try {
      const listFutures = await ProjectApi.getListAllFeatureRequest()
      dispatch(projectActions.setFeatures(listFutures as any as FeatureModel[]))
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    }
  }

  const getProjectDetail = async () => {
    try {
      const projectDetail = await ProjectApi.getProjectDetailRequest(idProject)
      dispatch(projectActions.setProject(projectDetail as any as ProjectModel))
    } catch (error) {
      notification.warning({
        message: String(error)
      })
    }
  }

  const getSearch = () => {
    const search = new URLSearchParams(location.search)
    const tab = search.get('tab')
    setActiveTab(tab || TABS.PLAN)
  }

  useEffect(() => {
    if (idProject) {
      getEpic()
      getFeatures()
      getProjectDetail()
      getSearch()
    }
  }, [idProject])

  const onChange = (key: string) => {
    navigate(`${location.pathname}?tab=${key}`)
    setActiveTab(key)
  }

  const items: TabsProps['items'] = [
    {
      key: TABS.PLAN,
      label: 'Plan',
      children: <CustomCalendar />
    },
    {
      key: TABS.RECRUITE,
      label: 'Recruitement',
      children: <Recruitement />
    }
  ]

  return (
    <Wrap>
      <WrapTabs activeKey={activeTab} items={items} onChange={onChange} />
    </Wrap>
  )
}
