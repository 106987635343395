import { createSelector } from '@reduxjs/toolkit'

const selectSelf = (state: RootState) => state.project

export const selector = {
  selectData: createSelector(selectSelf, state => state.data),
  selectList: createSelector(selectSelf, state => state.list),
  selectEpics: createSelector(selectSelf, state => state.epics),
  selectFeatures: createSelector(selectSelf, state => state.features),
  selectStories: createSelector(selectSelf, state => state.stories),
  selectJD: createSelector(selectSelf, state => state.jobsDescription)
}
