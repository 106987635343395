import styled from '@emotion/styled'
import { Col } from 'antd'

export const Wrap = styled.div`
  padding: 56px 120px 20px 120px;
  background-color: #f0f0f0;
  min-height: calc(100vh - 68px);
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`

export const WrapAddBtn = styled(Col)`
  text-align: right;
  .icon {
    margin-left: 5px;
  }
  &:hover {
    .icon {
      path {
        fill: #6fbd6f;
      }
    }
  }
`

export const CustomTag = styled.div`
  padding: 4px 12px 4px 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
  display: inline-block;
`
export const Dot = styled.div<{ color?: string }>`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 5px;
`
export const WrapIcons = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    &:first-child {
      margin-right: 20px;
    }
  }
`
